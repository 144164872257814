/* Desktop.css */
.desktop {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #008080;
    /* Classic Windows teal background */
    min-height: 100vh;
    overflow: hidden;
}

.desktop-window {
    background-color: white;
    color: black;
}

.desktop.dark .desktop-window {
    background-color: #000000;
    color: white;
    /* Dark mode for window */
}

.icons {
    display: flex;
    gap: 10px;
    margin: 10px;
}

.icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.icon img {
    width: 110px;
    height: 110px;
}

.icon p {
    margin: 0;
    text-align: center;
    line-height: 1;
    font-size: 12px;
    color: black;
}

.desktop.dark .icon p {
    color: white;
    /* Dark mode text for icons */
}