.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: black;
  color: white;
  font-size: 2em;
  font-family: 'Courier New', cursive;
}

/* App.css */
@font-face {
  font-family: 'RetroComputer';
  src: url('../public/fonts/retro_computer_personal_use.ttf') format('truetype');
}

body {
  font-family: 'RetroComputer', Arial, sans-serif;
}