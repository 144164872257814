/* Taskbar Styling */
.taskbar {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #c0c0c0;
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    box-sizing: border-box;
    z-index: 1000;
}

.taskbar.dark {
    background-color: #333;
    color: white;
}

/* Start Button Styling */
.start-button {
    display: flex;
    align-items: center;
    padding: 5px;
    background-color: #e5e5e5;
    color: black;
    font-weight: bold;
    cursor: pointer;
    border-right: 1px solid #a9a9a9;
}

.taskbar.dark .start-button {
    background-color: #444;
    color: white;
}

.start-button img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

/* Start Menu Styling */
.start-menu {
    position: absolute;
    bottom: 40px;
    left: 10px;
    width: 250px;
    background-color: #f0f0f0;
    border: 2px solid #a9a9a9;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    padding: 10px;
    z-index: 1001;
}

.start-menu.dark {
    background-color: #222;
    border-color: #555;
    color: white;
}

.start-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.start-menu li {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}

.start-menu li:hover {
    background-color: #c0c0c0;
}

.start-menu.dark li:hover {
    background-color: #555;
}

/* Larger Icons in Start Menu */
.menu-icon {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.start-menu li span {
    font-size: 14px;
    color: black;
}

.start-menu.dark li span {
    color: white;
}

/* Clock and Date Styling */
.taskbar-clock {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    color: black;
    margin-left: auto;
    padding-right: 15px;
}

.taskbar.dark .taskbar-clock {
    color: white;
}

.taskbar-clock div:first-child {
    font-weight: bold;
    font-size: 14px;
}

.taskbar-clock div:last-child {
    font-size: 12px;
}