.window {
    width: auto;
    height: auto;
    min-width: 600px;
    min-height: 260px;
    /* Optional: Set a maximum width */
    max-height: 900px;
    max-width: 900px;

    /* Optional: Set a maximum height */
    border: 7px solid #bfbfbf;
    background-color: white;
    position: absolute;
    top: 50px;
    left: 50px;
    z-index: 100;
    color: black;
    cursor: move;
    box-sizing: border-box;
    overflow-y: none;
    overflow-x: auto;
    /* Allows scrolling if content is too large */
    padding: 10px;
}

.window-header {
    background-color: #0078d7;
    padding: 5px;
    color: white;
    display: flex;
    justify-content: space-between;
    cursor: grab;
}

/* Window.css */
.window.dark-mode {
    background-color: #333;
    /* Dark background color */
    color: white;
    /* Light text color */
}

.window-header.dark-mode {
    background-color: #6d736f;
    /* Slightly lighter for header */
    color: white;
}

.window-body.dark-mode {
    background-color: #333;
    color: white;
}


.window-body {
    padding: 10px;
    box-sizing: border-box;
}

.details-link {
    color: rgb(95, 216, 168);
    cursor: pointer;
    text-decoration: underline;
    margin-left: 5px;
}

/* Enhanced Link Styles */
.details-link {
    color: #1e90ff;
    /* Bright blue for visibility */
    cursor: pointer;
    text-decoration: underline;
    margin-left: 5px;
    transition: color 0.3s;
    /* Smooth transition */
}

.details-link:hover {
    color: #0056b3;
    /* Darker shade on hover */
}


/* Guess Button Styling */
.guess-button {
    padding: 5px 10px;
    margin-left: 20px;
    color: white;
    background-color: #0078d7;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
}

.guess-button:hover {
    background-color: #005bb5;
}